import {FC, useState, createContext, useContext, useMemo} from 'react'
import {ID, WithChildren, initialListView} from '../../../../../../_metronic/helpers'
import {candidateProps, initialCandidateProps} from './_models'

const ListViewContext = createContext<candidateProps>(initialCandidateProps)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selectedId, setSelectedId] = useState<ID>(initialCandidateProps.selectedId)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(
    initialCandidateProps.isDetailModalOpen
  )
  const [candidatesData, setCandidatesData] = useState<any>([])
  const [selectedCandidate, setSelectedCandidate] = useState()
  const [loader,setLoader]=useState<boolean>(false)
  const [jobDetails,setJobDetails] = useState<any>();

  return (
    <ListViewContext.Provider
      value={{
        isDetailModalOpen,
        setIsDetailModalOpen,
        selectedId,
        setSelectedId,
        candidatesData,
        setCandidatesData,
        selectedCandidate,
        setSelectedCandidate,
        loader,
        setLoader,
        jobDetails,
        setJobDetails
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
